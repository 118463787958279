import { render, staticRenderFns } from "./Loading.vue?vue&type=template&id=242b8a60&scoped=true&"
var script = {}
import style0 from "./Loading.vue?vue&type=style&index=0&id=242b8a60&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "242b8a60",
  null
  
)

export default component.exports